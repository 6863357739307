@font-face {
  font-family: "champagne__limousinesregular";
  src: url("https://socialvisuals.com/fonts/champagne__limousines-webfont.eot");
  src: url("https://socialvisuals.com/fonts/champagne__limousines-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://socialvisuals.com/fonts/champagne__limousines-webfont.woff")
      format("woff"),
    url("https://socialvisuals.com/fonts/champagne__limousines-webfont.ttf")
      format("truetype"),
    url("https://socialvisuals.com/fonts/champagne__limousines-webfont.svg#champagne__limousinesregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  height: auto;
  margin: 0;
  padding: 0;
  /* overflow: hidden */
}

#canvas {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.NoSelect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
